<template>
  <div class="row content-wrapper">
    <h1 class="auth-title">회원가입</h1>
    <div class="col-md-12 auth-wrapper">
      <form class="form" @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="email" class="form-label">이메일</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="email"
            placeholder="이메일을 입력해주세요."
          />
        </div>
        <div class="mb-3">
          <label for="nickname" class="form-label">닉네임</label>
          <input
            type="text"
            class="form-control"
            id="nickname"
            v-model="nickname"
            placeholder="닉네임을 입력해주세요."
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">비밀번호</label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            placeholder="영문+숫자 10자 이상으로 입력해주세요."
          />
        </div>
        <div class="mb-5">
          <label for="password_confirmation" class="form-label"
            >비밀번호 재입력</label
          >
          <input
            type="password"
            class="form-control"
            id="password_confirmation"
            v-model="password_confirmation"
            placeholder="비밀번호를 다시 입력해주세요."
          />
        </div>
        <div class="mb-5 text-center">
          <button
            :disabled="
              !isEmailValid || !nickname || !password || !password_confirmation
            "
            type="submit"
            class="btn btn-default auth-submit-btn"
          >
            회원가입
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-12 text-center">
      <span class="auth-link-text">이미 제주투브이알 회원이신가요?</span>
      <router-link to="/login" class="auth-link">로그인</router-link>
    </div>
    <div class="col-md-12 text-center">
      <span class="auth-link-text">비밀번호를 잊으셨나요?</span>
      <router-link to="/forgot-password" class="auth-link"
        >비밀번호찾기</router-link
      >
    </div>
  </div>
</template>

<script>
import { validateEmail } from '../../utils/validation.js';

export default {
  data() {
    return {
      email: '',
      nickname: '',
      password: '',
      password_confirmation: '',
    };
  },
  computed: {
    isEmailValid() {
      return validateEmail(this.email);
    },
  },
  methods: {
    async submitForm() {
      try {
        const userData = {
          email: this.email,
          nickname: this.nickname,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };

        await this.$store.dispatch('REGISTER', userData);
        this.$router.push('/');
        this.$toast.success('회원가입을 환영합니다');
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast.error('이미 존재하는 이메일 주소입니다');
        } else if (error.response.status === 422) {
          this.$toast.error('비밀번호가 동일하지 않습니다');
        } else {
          this.$toast.error(
            '예기치 못한 오류가 있습니다. 나중에 다시 시도해 주세요'
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.auth-title {
  margin: 0;
  font-size: 30px;
  color: #333;
}
.auth-wrapper {
  padding-top: 40px;
}
.form-label {
  font-size: 18px;
  color: #333;
}
.auth-submit-btn {
  width: 250px;
  height: 60px;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
}
.auth-link-text {
  padding-right: 10px;
  font-size: 16px;
}
.auth-link {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-decoration: underline !important;
}
</style>
