<template>
  <RegisterComponent />
</template>

<script>
import RegisterComponent from '@/components/auth/RegisterComponent.vue';

export default {
  components: {
    RegisterComponent,
  },
};
</script>

<style></style>
